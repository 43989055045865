
























































































































































































































































































































































































































































.complaint-wrapper {
  padding-top: 60px;
  padding-bottom: 100px;
  .step-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .step-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 318px;
      height: 60px;
      color: #333;
      font-size: 22px;
      background-image: url(https://mktv-in-cdn.mockuai.com/16170121647535029.png);
      background-size: cover;
      &.active {
        color: #fff;
        background-image: url(https://mktv-in-cdn.mockuai.com/16170121942916286.png);
      }
      &:first-child {
        color: #fff;
        left: 0;
        background-image: url(https://mktv-in-cdn.mockuai.com/16170121149065589.png);
      }
      &:nth-child(2) {
        left: -16px;
      }
      &:nth-child(3) {
        left: -32px;
      }
    }
  }
  .explain {
    margin-bottom: 60px;
    padding: 40px 20px;
    background-color: #f7f8fb;
    border-radius: 4px;
    h3 {
      margin-bottom: 16px;
      line-height: 33px;
      font-weight: 500;
      font-size: 24px;
      color: #333;
    }
    ul li {
      line-height: 30px;
      font-weight: 400;
      font-size: 18px;
      color: #333;
    }
  }
  .actions {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 84px;
    background-color: #fff;
    border-top: 1px solid #eee;
    .btn {
      height: 44px;
      line-height: 44px;
      margin-right: 16px;
      padding: 0 45px;
      text-align: center;
      background-color: #ff6000;
      border-radius: 2px;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background: rgba(255, 96, 0, 0.85);
      }
      &.btn-primary {
        border: 1px solid #dedede;
        background-color: #fff;
        color: #333;
        font-weight: 400;
        &:hover {
          border: 1px solid #ff6000;
          color: #ff6000;
        }
      }
      &.btn-only {
        width: 300px;
      }
    }
  }
  .form {
    .form-area {
      margin-bottom: 60px;
      &.center {
        /deep/ .el-form-item {
          display: flex;
          align-items: center;
          .el-form-item__content {
            margin-left: 0!important;
          }
        }
      }
      &.upload {
        .el-radio-group {
          margin-top: 10px;
        }
      }
    }
    .form-title {
      margin-bottom: 30px;
      line-height: 25px;
      color: #333;
      font-size: 18px;
    }
    .tips {
      margin-bottom: 30px;
      font-weight: 400;
      font-size: 15px;
      color: #999;
    }
    /deep/ .el-form-item__label {
      padding-right: 18px;
      font-size: 16px;
      color: #333;
    }
    /deep/ .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #ff6000;
            background-color: #ff6000;
          }
        }
      }
      .el-radio__label {
        white-space: initial;
        line-height: 22px;
        font-weight: 400;
        font-size: 16px;
        color: #333;
      }
      &.radio-block {
        display: flex;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .el-radio__input {
          position: relative;
          top: 4px;
        }
        
      } 
      
    }
    .normal-input {
      width: 300px;
    }
    .distpicker-address-wrapper {
      /deep/ label > select {
        // color: #999;
        font-size: 14px;
      }
    }
    .address-textarea {
      margin-top: 20px;
      /deep/ .el-textarea__inner {
        width: 300px;
        height: 119px;
      }
    }
    &.confirm-info {
      /deep/  .el-form-item__content {
        font-size: 16px;
        color: #333;
      }
      .img {
        display: inline-block;
        margin-right: 30px;
        width: 90px;
        height: 90px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: scale-down;
        cursor: pointer;
      }
    }
  }
  .result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 116px;
    .icon {
      width: 80px;
      height: 80px;
      margin-bottom: 40px;
    }
    .title {
      margin-bottom: 24px;
      color: #333;
      font-weight: 400;
      font-size: 24px;
    }
    .desc {
      margin-bottom: 50px;
      line-height: 20px;
      color: #999;
      font-weight: 400;
      font-size: 18px;
    }
    .btn {
      height: 44px;
      line-height: 44px;
      padding: 0 55px;
      background-color: #fff;
      border: 1px solid #dedede;
      border-radius: 2px;
      text-align: center;
      color: #333;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .mb-10 {
    margin-bottom: 10px!important;
  }
}
